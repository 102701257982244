var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-alert',{staticClass:"alert alert-custom alert-white alert-shadow fade show gutter-b",attrs:{"show":"","variant":"light"}},[_c('div',{staticClass:"alert-icon"},[_c('span',{staticClass:"svg-icon svg-icon-lg"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Tools/Compass.svg"}})],1)]),_c('div',{staticClass:"alert-text"},[_vm._v(" Berikut adalah "),_c('b',[_vm._v("Daftar Stock Opname")]),_vm._v(" yang ada di "+_vm._s(_vm.clinic_name)+" ")]),(_vm.btn)?_c('b-button',{attrs:{"squared":"","variant":"success"},on:{"click":function($event){return _vm.$router.push('/stock-opnames/add')}}},[_vm._v("Tambah")]):_vm._e()],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('Card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-md-6"},[_c('button',{staticClass:"btn mx-1 btn-timeseries",class:{
                  'btn-info': _vm.btnActive.days,
                  'btn-outline-info': _vm.btnActive.days == false,
                },on:{"click":function($event){return _vm.btnActiveOnClick('days')}}},[_vm._v(" Hari Ini ")]),_c('button',{staticClass:"btn mx-1 btn-timeseries",class:{
                  'btn-info': _vm.btnActive.month,
                  'btn-outline-info': _vm.btnActive.month == false,
                },on:{"click":function($event){return _vm.btnActiveOnClick('month')}}},[_vm._v(" Bulan Ini ")]),_c('button',{staticClass:"btn mx-1 btn-timeseries",class:{
                  'btn-info': _vm.btnActive.lastMonth,
                  'btn-outline-info': _vm.btnActive.lastMonth == false,
                },on:{"click":function($event){return _vm.btnActiveOnClick('lastMonth')}}},[_vm._v(" Bulan Kemarin ")]),_c('button',{staticClass:"btn mx-1 btn-timeseries",class:{
                  'btn-info': _vm.btnActive.year,
                  'btn-outline-info': _vm.btnActive.year == false,
                },on:{"click":function($event){return _vm.btnActiveOnClick('year')}}},[_vm._v(" Tahun Ini ")]),_c('button',{staticClass:"btn mx-1 btn-timeseries",class:{
                  'btn-info': _vm.btnActive.period,
                  'btn-outline-info': _vm.btnActive.period == false,
                },on:{"click":_vm.btnPeriodeOnClick}},[_vm._v(" Periode ")])]),_c('div',{staticClass:"col-md-3"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{attrs:{"squared":""}},[_c('b-icon-search')],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Cari Berdasar Petugas"},on:{"keyup":_vm.filterByName},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1)]),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'actions' ? '20%' : '' })})})}},{key:"cell(status)",fn:function(data){return [(data.item.status_id == 0)?_c('span',[_c('i',{staticClass:"fas fa-circle text-danger"}),_vm._v(" "+_vm._s(data.item.status))]):_vm._e(),(data.item.status_id == 1)?_c('span',[_c('i',{staticClass:"fas fa-circle text-success"}),_vm._v(" "+_vm._s(data.item.status))]):_vm._e()]}},{key:"cell(count)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.count)+" item")])]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-outline-info",attrs:{"size":"sm","title":"Cetak"},on:{"click":function($event){return _vm.$router.push({
                    path: '/stock-opnames/print/' + data.item.id,
                  })}}},[_c('i',{staticClass:"fas fa-print px-0"})]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Detail"},on:{"click":function($event){return _vm.$router.push({
                    path: '/stock-opnames/detail/' + data.item.id,
                  })}}},[_c('i',{staticClass:"fas fa-eye px-0"})]),(data.item.status_id == 0 && _vm.btn == true)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-success",attrs:{"size":"sm","title":"Edit"},on:{"click":function($event){return _vm.$router.push({
                    path: '/stock-opnames/edit/' + data.item.id,
                  })}}},[_c('i',{staticClass:"fas fa-edit px-0"})]):_vm._e(),(data.item.status_id == 0 && _vm.btn == true)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-danger",attrs:{"size":"sm","title":"Hapus"},on:{"click":function($event){return _vm.btnDeleteOnClick(data.item.id)}}},[_c('i',{staticClass:"fas fa-trash px-0"})]):_vm._e()]}}])}),(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},on:{"page-click":_vm.pageOnClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()]},proxy:true}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }